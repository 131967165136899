import { FC, useCallback } from 'react';
import { FilterProps } from './FilterProps';
import { graphql } from 'gql.tada';
import { useLazyQuery } from '@apollo/client';
import GenericFilter from './GenericFilter';

export const AllAttachmentsFilterQuery = graphql(`
  query AllAttachmnetsFilter($tfId: UUID!) {
    clientFormAttachmentsAsync(templateFormId: $tfId) {
      id
      file {
        name
      }
    }
  }
`);

const AllAttachmentsFilter: FC<FilterProps> = (props) => {
  const [loadQuery] = useLazyQuery(AllAttachmentsFilterQuery);

  const loadPage = useCallback(
    (selectedTemplateId: string, _: unknown, __: unknown, pageNum: number) => {
      return (
        loadQuery({
          variables: {
            tfId: selectedTemplateId,
          },
        }).then((res) => {
          return {
            meta: {
              totalCount: res.data?.clientFormAttachmentsAsync?.length ?? 0,
              pageNumber: pageNum,
              success: true,
              code: 200,
              message: '',
            },
            data: res.data?.clientFormAttachmentsAsync?.map((x, i) => ({ id: i, text: x!.file?.name as string, value: x!.id as string })) ?? [],
            status: 200,
            headers: {},
          };
        }) ?? Promise.resolve({ data: [], totalCount: 0, pageNumber: 0 })
      );
    },
    [loadQuery],
  );

  return <GenericFilter {...props} loadPage={loadPage} includeNoData={false} />;
};

export default AllAttachmentsFilter;
